// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
// =================================================
// FUNCTIONAL COMPONENT
const DateTimeSelectorItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.DateTimeSelectorItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias),
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection,
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId,
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id],
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set value
    if (value !== null) {
      value = value.startOf("minute").toISO({
        suppressMilliseconds: true,
        suppressSeconds: true,
        includeOffset: false, // for survey response only
      });
    }
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value }),
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value }),
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      }),
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <ArticleFormControl
      article={props.article}
      required={props.required}
      isInvalid={localValidation && localValidation.isInvalid}
      validationMsg={localValidation && localValidation.msg[props.alias]}
    >
      <MobileDateTimePicker
        disabled={props.uneditable}
        label={
          !value
            ? t("Click to select a date and time")
            : DateTime.fromISO(value).hour >= 0 &&
                DateTime.fromISO(value).hour < 6
              ? t("Night time on")
              : DateTime.fromISO(value).hour >= 6 &&
                  DateTime.fromISO(value).hour < 12
                ? t("Morning time on")
                : DateTime.fromISO(value).hour >= 12 &&
                    DateTime.fromISO(value).hour < 18
                  ? t("Afternoon time on")
                  : DateTime.fromISO(value).hour >= 18 &&
                      DateTime.fromISO(value).hour <= 23
                    ? t("Evening time on")
                    : t("Selected time")
        }
        value={
          value && DateTime.fromISO(value).isValid
            ? DateTime.fromISO(value)
            : null
        }
        format={props.article.options.dateTimeFormat}
        minDate={
          props.article.options.disablePast
            ? DateTime.now()
            : DateTime.fromISO(props.article.options.min)
        }
        maxDate={
          props.article.options.disableFuture
            ? DateTime.now()
            : DateTime.fromISO(props.article.options.max)
        }
        ampm={props.article.options.ampm}
        ampmInClock={props.article.options.ampm}
        minutesStep={props.article.options.step}
        slotProps={{
          dialog: {
            id: "time-picker",
          },
          actionBar: {
            actions: props.article.options.showTodayButton
              ? ["today", "clear", "cancel", "accept"]
              : ["clear", "cancel", "accept"],
          },
          toolbar: {
            toolbarTitle: !props.value
              ? t("Click to select a date and time")
              : t("Selected date and time"),
          },
          TextField: {
            name: props.alias,
            variant: "filled",
            color: "secondary",
            helperText: props.article.options.dateTimeFormat?.toLowerCase(),
            className: "bg-white",
          },
        }}
        onChange={(newValue) => handleSetSingleResponse(props.alias, newValue)}
      />
    </ArticleFormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default DateTimeSelectorItem;
